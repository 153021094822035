<template>
	<Header />
	<Sheet :data="general" view="general" />
</template>

<script>
import Header from '@/components/Header';
import Sheet from '@/components/Sheet';
import { mapState } from 'vuex';

export default {
	name: 'General',
	components: {
		Header,
		Sheet
	},
	computed: {
		...mapState(['general']),
	},
	props: {
		msg: String
	},
	data() {
		return {

		}
	},
	mounted() {
		this.$store.commit('setView', 'general');
	}
}


</script>


<style lang="scss" scoped>
	
</style>
