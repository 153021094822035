<template>
	<Header />
	
      <iframe id="datastudio" src="https://datastudio.google.com/embed/reporting/34817a9b-9b10-4eb8-bfe3-068d81c72edc/page/FVyuC" frameborder="0"></iframe>
            
</template>

<script>
import Header from '@/components/Header';
import { mapState } from 'vuex';

export default {
	name: 'Report',
	components: {
		Header,
	},
	computed: {
		...mapState(['current']),
	},
	props: {
		msg: String
	},
	data() {
		return {

		}
	},
	mounted() {
		
	}
}


</script>


<style lang="scss" scoped>
	
      #datastudio {
            width: 100%;
            height: 100vh;
      }
</style>
