<template>
	<div :class="['loader','absolute',(loading ? 'loading' : '')]">
		<div class="la-ball-fall">
			<div></div>
			<div></div>
			<div></div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Preloader",
		props: ["loading"]
	}
	
</script>

<style lang="scss" scoped>
	.loader {
		background: rgba(255,255,255,0.8);
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		transition: all 0.4s;
		opacity: 0;
		pointer-events: none;
		
		&.loading {
			opacity: 1;
		}
	}
	.la-ball-fall {
		width: 100%;
		text-align: center;
		color: black;
	}
</style>