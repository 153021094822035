<template>
	<th :key="header.id" class="text-left relative tooltip">
		<div class="flex items-center">
			<span class="flex-1">{{header}}</span>
			<span class="arrow"></span>

			<div v-if="getTooltip" class="label">{{getTooltip}}</div>
		</div>
	</th>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		name: "Header",
		props: ["header"],
		computed: {
			...mapState(['metaData']),
			getTooltip: function() {
				return this.metaData[this.header] ? this.metaData[this.header].desc : false;
			},	
			hiddenColCheck: function() {
				return this.header.includes('-') ? 'hidden' : '';
			}
		},
	}
	
</script>

<style lang="css" scoped>
	.label {
		background: white;
		color: black;
		border: 1px solid #E8E8E8;
		bottom: auto !important;
		top: calc(50% + 30px);
		left: 50% !important;
		transform: translateX(-50%);
		width: 170px;
		font-weight: 600;

		//opacity:1 !important;
	}
</style>